<template>
    <div>
        <Loader v-if="data_show_loader" />
        <div class="wrapper" v-else>
          <!-- Sidebar  -->
          <SideBarStyle1 :items="verticalMenu" :logo="logo" @toggle="sidebarMini" />

          <vue-pull-refresh :on-refresh="reloadPage">
            <!-- TOP Nav Bar -->
            <NavBarGide title="Dashboard" :homeURL="{ name: 'dashboard1.home' }" @toggle="sidebarMini" :logo="logo">
            </NavBarGide>
          </vue-pull-refresh>

          <div  v-if ="
            (this.$route.name == 'admin-layout')
          " class="header-for-bg">
            <div class="background-header position-relative">
              <img :src="$route.meta.img" class="img-fluid w-100" alt="header-bg">
              <div class="title-on-header">
                <div class="data-block">
                  <h2>
                    {{$route.meta.name}}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <!-- Right Sidebar Panel End-->
          <!-- TOP Nav Bar END -->
          <div id="content-page" class="content-page pr-0">
            <div class="container-fluid uptolaptop">
              <transition name="router-anim" :enter-active-class="`animated ${animated.enter}`" mode="out-in"
                          :leave-active-class="`animated ${animated.exit}`">
                <router-view/>
              </transition>
            </div>
          </div>
        </div>
        <FooterStyle1>
          <template v-slot:left>
            <li class="list-inline-item">
              <a href="/content/PrivacyPolicy" target="_blank">Privacy Policy</a>
            </li>
            <li class="list-inline-item">
              <a href="/content/Tnc" target="_blank">Terms of Use</a>
            </li>
          </template>
          <template v-slot:right>
            Copyright {{new Date().getFullYear()}} <a href="#">Gide</a> All Rights Reserved.
          </template>
        </FooterStyle1>
      </div>
</template>
<script>
import Loader from "../components/socialvue/loader/Loader"
import SideBarStyle1 from "../components/socialvue/sidebars/SideBarStyle1.vue"
import NavBarGide from "../components/socialvue/navbars/NavBarGide.vue"
import SideBarItems from "../FackApi/json/AdminSideBar"
import profile from "../assets/images/user/user-1.jpeg"
import logo from "../assets_gide/img/logo/GideWhiteNRed.jpeg"
import FooterStyle1 from "../components/socialvue/footer/FooterStyle1"
import { socialvue } from "../config/pluginInit"
import { mapActions } from "vuex"
var vuePullRefresh = require("vue-pull-refresh")

export default {
  name: "AdminLayout",
  components: {
    Loader,
    SideBarStyle1,
    NavBarGide,
    FooterStyle1,
    vuePullRefresh
  },
  mounted () {
    this.logo = logo
    this.changeText()
  },
  computed: {
    currentRouteName () {
      return this.$route.name
    }
  },
  watch: {
  },
  // sidebarTicket
  data () {
    return {
      data_show_loader: false,
      animated: { enter: "zoomIn", exit: "zoomOut" },
      animateClass: [
        { value: { enter: "zoomIn", exit: "zoomOut" }, text: "Zoom" },
        { value: { enter: "fadeInUp", exit: "fadeOutDown" }, text: "Fade" },
        { value: { enter: "slideInLeft", exit: "slideOutRight" }, text: "Slide" },
        { value: { enter: "rotateInDownLeft", exit: "rotateOutDownLeft" }, text: "Roll" }
      ],
      verticalMenu: SideBarItems,
      userProfile: profile,
      logo: logo,
      window: window
    }
  },
  methods: {
    changeText () {
      const textPullTORefresh = document.querySelector(".pull-down-content--label")
      if (textPullTORefresh) {
        textPullTORefresh.innerText = "Pull to refresh"
      }
    },
    reloadPage () {
      window.location.reload()
    },
    sidebarMini () {
      socialvue.triggerSet()
    },
    logout () {
      localStorage.removeItem("user")
      localStorage.removeItem("access_token")
      this.$router.push({ name: "auth1.sign-in1" })
    },
    langChange (lang) {
      this.langChangeState(lang)
      this.$i18n.locale = lang.value
      document.getElementsByClassName("iq-show")[0].classList.remove("iq-show")
    },
    ...mapActions({
      langChangeState: "Setting/setLangAction"
    }),
    onRefresh: function () {
      return new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve()
        }, 100)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  @import url("../assets/css/custom.css");
  @import url("../assets/css/PriceSlider.css");
  .content-page{
    padding-left: 30px;
  }
  .pull_to_refresh{
    .content-page{
      padding-top: 15px;
    }
  }
</style>
